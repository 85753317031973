import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["logRow", "logDetail"]

  connect() {
    this.showLogDetail(0)
    this.highlightLogRow(0)
  }

  selectLog(event) {
    const selectedIndex = event.currentTarget.dataset.index
    this.showLogDetail(selectedIndex)
    this.highlightLogRow(selectedIndex)
  }

  showLogDetail(index) {
    this.logDetailTargets.forEach((detail, detailIndex) => {
      detail.style.display = detailIndex == index ? 'block' : 'none'
    })
  }

  highlightLogRow(index) {
    this.logRowTargets.forEach((row, rowIndex) => {
      row.classList.toggle('bg-gray-100', rowIndex == index)
    })
  }
}
