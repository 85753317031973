import "./controllers"

// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

var jQuery = require('jquery')

document.addEventListener("turbolinks:load", () => {

    jQuery('[data-toggle="reveal"]').on('click', function(){
        jQuery(this).replaceWith('<div class="preview">' + jQuery(this).parent().find('input.code').attr('value') + '</div>');
    })

    jQuery('[data-toggle="copy"]').on('click', function(){
        var copyText = jQuery(this).parent().find('input.code')[0];
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        copyText.setSelectionRange(0, 0);
        alert('Copied')
    })

})
